import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}
    `}


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const SectionTitle = styled.h3`
    padding-top: 1em;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Jak se sbalit na vícedenní trek na lehko?</Title>
            <Info>
                <Author>Simona</Author>
                <Date>7. 5. 2023</Date>
            </Info>
            <StyledFigure >
                <StaticImage src="../assets/images/blog/trek-nalehko/trek1.jpg" alt=""></StaticImage>
                <StyledFigcaption>Zdroj: pixabay</StyledFigcaption>
            </StyledFigure>
            <p>Na pár dovolených už jsem se sbalit dokázala, nikdy jsem ale nemusela vše, co budu potřebovat nosit 7 dní v kuse na zádech. To znamenalo jediné, dobře promyslet každý kousek oblečení, řasenku nebo knihu, kterou s sebou ponesu.</p>
            <p>Dalším limitem bylo, že jsem se musela zabalit jen do příručního zavazadla dle podmínek letecké společnosti Ryanair (55 cm x 40 cm x 20 cm) a zpět TAP Portugal.</p>
            <StyledFigure >
                <StaticImage src="../assets/images/blog/trek-nalehko/trek2.jpeg" alt=""></StaticImage>
                <StyledFigcaption></StyledFigcaption>
            </StyledFigure>
            <p>Jako klasický projektový manažer jsem si prvně zkoukla nějaká videa, jak se efektivně
                balí, opakování matka moudrosti. Nepsaným pravidlem (i když já si to někde taky
                přečetla, takže napsáno už to bylo asi několikrát) je, že pokud jdete na vícedenní trek
                na lehko, je dobré nenakládat si na záda více než 10 % své hmotnosti. A tím jsem se
                také řídila. Nakonec jsem se balila do dámského batohu <a href="https://www.4camping.cz/p/damsky-turisticky-batoh-osprey-sirrus-44/?gclid=CjwKCAiAh9qdBhAOEiwAvxIokxqPkiy2C5OK5kmAt3Uw0wVcC-NzLmoLqIwIua5cFNT2_w1wD6yJEhoC0PEQAvD_BwE#modra">Osprey Sirrus 44</a>.</p>
            <p>Připravila jsem si seznam:</p>
            <p>pas, občanka, platební karta, hotovost, deník na zápisky, propiska, mobil, hodinky,
                sluchátka, nabíječky, powerbanka, sluneční brýle, špunty do uší, maska na oči,
                sandále, tenisky, ledvinka, selfie-tyč/stojan, vak na pití 2l</p>
            <p><i>Oblečení:</i></p>
            <p>mikina, 2x letní merino tričko, <a href="https://www.zajo.net/sk/priedusne-damske-nohavicky-z-merino-vlny-elsa-merino-w-briefs-10888?parameters%5B1%5D=617">2x merino kalhotky</a>, 2x ponožky, 2x sportovní
                podprsenka, 2x kraťasy,
                1x legíny, 1x šortky a tričko na spaní, 1x letní univerzální overal, plavky,
                rychleschnoucí ručník, kšiltovka</p>
            <p><i>Drogerie:</i></p>
            <p>šampon, mýdlo, prací mýdlo, kartáček na zuby, pasta, žiletka, 2x opalovací krém
                (obličej, tělo), krém na obličej, chladivý gel na nohy, nožo-lžíce, provázek na prádlo,
                4x spínací špendlík, papírové kapesníky, náplasti, 3x proteinová tyčinka, plastová
                krabička na ovoce, základní léky (smecta, paralen, rowatinex)</p>
            <StyledFigure >
                <StaticImage src="../assets/images/blog/trek-nalehko/trek3.jpeg" alt=""></StaticImage>
                <StyledFigcaption></StyledFigcaption>
            </StyledFigure>

            <p>Vše, co jsem si zabalila jsem využila. Ani se nedá říct, že jsem měla navíc jen
            ponožky a tenisky (o kterých to ráda říkám) i přes to, že jsem je využila v letadle na
            cestě tam a zpět. Díky tomu, že od prvního dne poměrně dost intenzivně pršelo, začala
            jsem svůj trek v sandálech a také ho v nich celý odešla.</p>

            <StyledFigure >
                <StaticImage src="../assets/images/blog/trek-nalehko/trek4.jpeg" alt=""></StaticImage>
                <StyledFigcaption>Míň je někdy víc. Někteří do protisměru jdoucí pohorkami vybavení chodci se divili, když jsem říkala, že jdu celý trek jen v těchto sandálech.</StyledFigcaption>
            </StyledFigure>

            <p>Na konci jsem je s poděkováním nechala na pospas místní portugalské odpadní společnosti.</p>



        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
